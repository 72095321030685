@import url(//fonts.googleapis.com/css?family=Lato:300:400);

.img-header{
  display: flex;
  justify-content: center;
}

img{
  position: absolute;
  width: 30%;
  height: auto;
  margin-top: 5vh;
}

.background {
  /* height: max-content; */
  /* height: 100vh;
  width: 100vw; */
  /* background: linear-gradient(60deg, rgb(18, 194, 15) 0%, rgb(231, 106, 11) 100%); */
}

.bottom{
	position:fixed;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: .5;
}
.ig-icon{
  font-size: 60px;
  position: relative;
  /* margin-top: 60vh; */
  color: azure;
  z-index: 99;

}
/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -1s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -5s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -7s;
  animation-duration: 20s;
}
.parallax > use:nth-child(5) {
  animation-delay: -10s;
  animation-duration: 10s;
}
@keyframes move-forever {
  0% {
   transform: translate3d(-90px,0,0);
  }
  100% { 
    transform: translate3d(85px,0,0);
  }
}



.glitch
  {
    position: relative;
    width: 100%;
    height: 100vh;
background: url(https://images.unsplash.com/photo-1505118380757-91f5f5632de0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1526&q=80);    background-size: cover;
  }

.glitch:before
  {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
background: url(https://images.unsplash.com/photo-1505118380757-91f5f5632de0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1526&q=80);    background-size: cover; /* contain for split effect */
    opacity: .5;
    mix-blend-mode: hard-light;
    animation: glitch2 10s linear infinite;
  }

/* .glitch:hover:before
{
  animation: glitch1 1s linear infinite;
} */

@keyframes glitch1
{
  0%
  {
    background-position: 0 0;
    filter: hue-rotate(0deg);
  }
  10%
  {
    background-position: 5px 0;
  }
  20%
  {
    background-position: -5px 0;
  }
  30%
  {
    background-position: 15px 0;
  }
  40%
  {
    background-position: -5px 0;
  }
  50%
  {
    background-position: -25px 0;
  }
  60%
  {
    background-position: -50px 0;
  }
  70%
  {
    background-position: 0 -20px;
  }
  80%
  {
    background-position: -60px -20px;
  }
  81%
  {
    background-position: 0 0;
  }
  100%
  {
    background-position: 0 0;
    filter: hue-rotate(360deg);
  }
}

@keyframes glitch2
{
  0%
  {
    background-position: 0 0;
    filter: hue-rotate(0deg);
  }
  10%
  {
    background-position: 15px 0;
  }
  15%
  {
    background-position: -15px 0;
  }
  20%
  {
    filter: hue-rotate(360deg);
  }
  25%
  {
    background-position: 0 0;
    filter: hue-rotate(0deg);
  }
  100%
  {
    background-position: 0 0;
    filter: hue-rotate(0deg);
  }
}


#instagram {
  background-color: #3750dd;
  /* color: #fff; */
  /* box-shadow: 2px 2px 2px #ff080880, 10px 1px 12px #bf9e9e80,
    2px 2px 10px #3f1d1d80, 2px 2px 3px #10bc4180, inset 2px 2px 10px #dff32d80,
    inset 2px 2px 10px #ef16bc80, inset 2px 2px 10px #09cb8780,
    inset 2px 2px 10px #7544bb80; */
  border-radius: 40%;
  padding: 11px 19px;
  animation: animation 3s linear infinite;
}
#instagram {
  animation-delay: 0.3s;
}
#instagram {
  animation-delay: 0.7s;
}
#instagram {
  animation-delay: 0.1s;
}

@keyframes animation {
  from {
    filter: hue-rotate(0deg);
  }
  to {
    filter: hue-rotate(360deg);
  }
}

/*Shrinking for mobile*/
@media (max-width: 768px) {
  img{
    width: 70%;
  }
  .ig-icon{
    margin-top: 50vh;
  }
  .waves {
    height:60vh;
    min-height:60vh;
  }
  .content {
    height:40vh;
  }
  .bottom{
    height: 250px;
  }
}

@media only screen and (min-width: 1200px) {

.ig-icon{
  margin-top: 70vh;
}

  .bottom{
    width:100vw;
    height: 150px;
  }
} 